import Cookie from "js-cookie";

const checkToken = Cookie.get("access_token");

// Get values from localStorage
const localStorageToken = window.localStorage.getItem("access_token");
const localStorageUserInfo = window.localStorage.getItem("user_info");
const localStorageUserRole = localStorageUserInfo
  ? JSON.parse(localStorageUserInfo).user_type
  : null;

// Use localStorage values if available, otherwise fallback to Cookie values
const token = localStorageToken || checkToken;
const role = localStorageUserRole || "ADMIN";

// Define role enum
const Role = {
  ADMIN: "ADMIN",
  SALE: "SALE",
  USER: "USER",
};

export const customRoutes = !token
  ? {
      redirects: {
        "/": "/login",
        "/set-password?token=:token": "/set-password?token=:token", // New condition for token
      },
      routes: [
        {
          type: "route",
          path: "login",
          exact: true,
          routes: [
            {
              type: "application",
              name: "@gtt/login",
            }
          ]
        },
        {
          type: "route",
          path: "set-password",
          routes: [
            {
              type: "application",
              name: "@gtt/admin"
            }
          ]
        }, 
      ],
    }
  : {
      routes: [
        {
          type: "application",
          name: "@gtt/nav",
        },
        {
          type: "route",
          path: "admin",
          routes: [{ type: "application", name: "@gtt/admin" }],
          roles: [Role.ADMIN],
        },

        {
          type: "route",
          path: "help-center",
          routes: [{ type: "application", name: "@gtt/portal-help-center" }],
          roles: [Role.ADMIN],
        },
        {
          type: "route",
          path: "user",
          routes: [{ type: "application", name: "@gtt/user" }],
          roles: [Role.ADMIN],
        },
        {
          type: "route",
          path: "user-group",
          routes: [{ type: "application", name: "@gtt/user" }],
          roles: [Role.ADMIN],
        },
        {
          type: "route",
          path: "notification",
          routes: [{ type: "application", name: "@gtt/notification" }],
          roles: [Role.ADMIN],
        },
        {
          type: "route",
          path: "content-manage",
          routes: [{ type: "application", name: "@gtt/content" }],
          roles: [Role.ADMIN],
        },
        {
          type: "route",
          path: "referral",
          routes: [{ type: "application", name: "@gtt/referral" }],
          roles: [Role.ADMIN],
        },
        {
          type: "route",
          path: "landing-page",
          routes: [{ type: "application", name: "@gtt/core" }],
          roles: [Role.ADMIN],
        },
        {
          type: "route",
          path: "home-manage",
          routes: [{ type: "application", name: "@gtt/content" }],
          roles: [Role.ADMIN],
        },
        {
          type: "route",
          path: "banner-manage",
          routes: [{ type: "application", name: "@gtt/banner-manage" }],
          roles: [Role.ADMIN],
        },
        {
          type: "route",
          path: "core",
          routes: [{ type: "application", name: "@gtt/core" }],
          roles: [Role.ADMIN],
        },
        {
          type: "route",
          path: "privilege",
          routes: [{ type: "application", name: "@gtt/privilege" }],
          roles: [Role.ADMIN],
        },
        {
          type: "route",
          path: "app-preview",
          routes: [{ type: "application", name: "@gtt/app-preview" }],
          roles: [Role.ADMIN],
        },
        {
          type: "route",
          path: "export-report",
          routes: [{ type: "application", name: "@gtt/privilege" }],
          roles: [Role.ADMIN, Role.SALE],
        },
      ].filter((route) => !route.roles || (route.roles && route.roles.includes(role))),
    };
